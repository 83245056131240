import defaultLayout from "@/layouts/side-nav-outer-toolbar";
import loadView from "../common/loadView";

export default [
  {
    path: "/taxi-depot/:mode/:id?",
    name: "taxi-depot",
    meta: {
      requiresAuth: true,
      requiresPermission: true,
      layout: defaultLayout,
      navPermission: "Таксопарк"
    },
    component: loadView("taxi-depot/taxi-depot-detail-view"),
  },
  {
    path: "/taxi-depots",
    name: "taxi-depots",
    meta: {
      requiresAuth: true,
      requiresPermission: true,
      layout: defaultLayout,
      navPermission: "Таксопарк"
    },
    component: loadView("taxi-depot/taxi-depot-list-view"),
  },
];
