import defaultLayout from "@/layouts/side-nav-outer-toolbar";
import loadView from "../common/loadView";

export default [
  {
    path: "/limit-phone/:mode/:id?",
    name: "limit-phone",
    meta: {
      requiresAuth: true,
      requiresPermission: true,
      layout: defaultLayout,
      navPermission: "Ограничения"
    },
    component: loadView("limit-phones/limit-phone-detail-view"),
  },
  {
    path: "/limit-phones",
    name: "limit-phones",
    meta: {
      requiresAuth: true,
      requiresPermission: true,
      layout: defaultLayout,
      navPermission: "Ограничения"
    },
    component: loadView("limit-phones/limit-phone-list-view"),
  },
];
