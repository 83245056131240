import defaultLayout from "@/layouts/side-nav-outer-toolbar";
import loadView from "../common/loadView";

export default [
  {
    path: "/profile/:id?",
    name: "profile",
    meta: {
      requiresAuth: true,
      requiresPermission: false,
      layout: defaultLayout,
      navPermission: "Сотрудники"
    },
    component: loadView("employees/employee-detail-view"),
  },
  {
    path: "/employee/:mode/:id?",
    name: "Employee",
    meta: {
      requiresAuth: true,
      requiresPermission: true,
      layout: defaultLayout,
      navPermission: "Сотрудники"
    },
    component: loadView("employees/employee-detail-view"),
  },
  {
    path: "/employees",
    name: "employees",
    meta: {
      requiresAuth: true,
      requiresPermission: true,
      layout: defaultLayout,
      navPermission: "Пользователи"
    },
    component: loadView("employees/employees-list-view"),
  },
];
