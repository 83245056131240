import 'devextreme/dist/css/dx.common.css';
import './themes/generated/theme.base.css';
import './themes/generated/theme.additional.css';
import { createApp }  from "vue";
import router from "./router/router";
import themes from "devextreme/ui/themes";

import { createPinia, } from "pinia";
import  resetStore  from './utils/reset-store'
import piniaPluginPersistedState from "pinia-plugin-persistedstate";
import { i18n } from './i18n';

import App from "./App";
import appInfo from "./app-info";

import { VueSignalR } from "@dreamonkey/vue-signalr";
import { connection } from "./hub-connection";

themes.initialized(() => {
    const app = createApp(App);
    const pinia = createPinia();
    pinia.use(resetStore);
    pinia.use(piniaPluginPersistedState);
    app.use(pinia);
    app.use(i18n);
    app.use(router);
    app.use(VueSignalR, { connection });
    app.config.globalProperties.$appInfo = appInfo;
    app.mount('#app');
});
