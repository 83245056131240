import defaultLayout from "@/layouts/side-nav-outer-toolbar";
import loadView from "../common/loadView";
export default [
  {
    path: "/work-completion-certificate",
    name: "work-completion-certificate-list",
    meta: {
      requiresAuth: true,
      requiresPermission: true,
      layout: defaultLayout,
      navPermission: "Новости"
    },
    component: loadView(
      "work-completion-certificate/work-completion-certificate"
    ),
  },
];
