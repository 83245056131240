import defaultLayout from "@/layouts/side-nav-outer-toolbar";
import loadView from "../common/loadView";

export default [
  {
    path: "/branding/:mode/:id?",
    name: "branding",
    meta: {
      requiresAuth: true,
      requiresPermission: true,
      layout: defaultLayout,
      navPermission: "Брендинг"
    },
    component: loadView("branding/branding-detail-view"),
  },
  {
    path: "/brandings",
    name: "brandings",
    meta: {
      requiresAuth: true,
      requiresPermission: true,
      layout: defaultLayout,
      navPermission: "Брендинг"
    },
    component: loadView("branding/branding-list-view"),
  },
];
