import defaultLayout from "@/layouts/side-nav-outer-toolbar";
import loadView from "../common/loadView";

export default [
  {
    path: "/role/:mode/:id?",
    name: "role",
    meta: {
      requiresAuth: true,
      requiresPermission: true,
      layout: defaultLayout,
      navPermission: "Роли"
    },
    component: loadView("role/role-detail-view"),
  },
  {
    path: "/roles",
    name: "roles",
    meta: {
      requiresAuth: true,
      requiresPermission: true,
      layout: defaultLayout,
      navPermission: "Роли"
    },
    component: loadView("role/role-list-view"),
  },
];
