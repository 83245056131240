import defaultLayout from "@/layouts/side-nav-outer-toolbar";
import loadView from "../common/loadView";

export default [
  {
    path: "/message/:mode/:id?",
    name: "message",
    meta: {
      requiresAuth: true,
      requiresPermission: true,
      layout: defaultLayout,
      navPermission: "Рассылки для менеджера"
    },
    component: loadView("messages/messages-detail-view"),
  },
  {
    path: "/messages",
    name: "messages",
    meta: {
      requiresAuth: true,
      requiresPermission: true,
      layout: defaultLayout,
      navPermission: "Рассылки для менеджера"
    },
    component: loadView("messages/messages-list-view"),
  },
];