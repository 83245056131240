import axios from "axios";
import router from "../router/router";
import { useSecurityStore } from "@/stores/security";

const requestAxios = axios.create({
  baseURL: process.env.VUE_APP_API_ROOT, 
});
const userType = process.env.VUE_APP_USER_TYPE;
requestAxios.interceptors.response.use(null, (error) => {
  if (error.response.status === 401) {
    return handleUnauthorizedError(error);
  }
  return Promise.reject(error);
});

async function handleUnauthorizedError(error) {
  const originalRequest = error.config;
  const securityStore = useSecurityStore();

  const refreshToken = securityStore.refreshToken;
  try {
    const response = userType === "Manager" ?
      await securityStore.issueNewToken(refreshToken) : await securityStore.issueNewClientToken(refreshToken);
    // Если токен был успешно обновлен, делаем повторный запрос с новым токеном
    originalRequest.headers.Authorization = 'Bearer ' + response.data.token;
    return requestAxios(originalRequest, response.data.token);
  } catch (tokenRefreshError) {
    // Если обновление токена не удалось, перенаправляем пользователя на страницу входа
    securityStore.destroyJwtData();
    router.push("/login-form");
    return Promise.reject(tokenRefreshError);
  }
}

requestAxios.interceptors.request.use(
  (config) => {
    if (!config.headers.Authorization) {
      const securityStore = useSecurityStore();
      const token = securityStore.token;
      if (token) {
        config.headers.Authorization = "Bearer " + token;
      }
    }
    return config;
  },
  (error) => Promise.reject(error)
);

export default requestAxios;
