<template>
  <div id="root">
    <div :class="cssClasses">
      <component
        :is="$route.meta.layout"
        :title="title"
        :is-x-small="screen.getScreenSizeInfo.isXSmall"
        :is-large="screen.getScreenSizeInfo.isLarge"
      >
        <div class="content">
          <router-view></router-view>
        </div>
        <template #footer>
          <app-footer />
        </template>
      </component>
    </div>
  </div>
</template>

<script>
import AppFooter from "./components/app-footer";
import { sizes, subscribe, unsubscribe } from "./utils/media-query";
import { locale, loadMessages } from "devextreme/localization";
import ruMessages from "devextreme/localization/messages/ru.json";
import {
  getCurrentInstance,
  reactive,
  onMounted,
  onBeforeUnmount,
  computed,
} from "vue";

function getScreenSizeInfo() {
  const screenSizes = sizes();

  return {
    isXSmall: screenSizes["screen-x-small"],
    isLarge: screenSizes["screen-large"],
    cssClasses: Object.keys(screenSizes).filter((cl) => screenSizes[cl]),
  };
}

export default {
  components: {
    AppFooter,
  },
  setup() {
    const vm = getCurrentInstance();

    loadMessages(ruMessages);
    locale("ru");

    const title = vm.proxy.$appInfo.title;
    const screen = reactive({ getScreenSizeInfo: {} });
    screen.getScreenSizeInfo = getScreenSizeInfo();

    function screenSizeChanged() {
      screen.getScreenSizeInfo = getScreenSizeInfo();
    }

    onMounted(() => {
      subscribe(screenSizeChanged);
    });

    onBeforeUnmount(() => {
      unsubscribe(screenSizeChanged);
    });

    const cssClasses = computed(() => {
      return ["app"].concat(screen.getScreenSizeInfo.cssClasses);
    });

    return {
      title,
      screen,
      cssClasses,
    };
  },
};
</script>

<style lang="scss">
@import "@/style/_mixins.scss";

@include bg-color;

html,
body {
  margin: 0px;
  min-height: 100%;
  height: 100%;
}

#root {
  height: 100%;
}

* {
  box-sizing: border-box;
}

.app {
  @import "./themes/generated/variables.base.scss";
  background-color: darken($base-bg, 5);
  display: flex;
  height: 100%;
  width: 100%;
}

.form-control {
  box-shadow: 0 0px 0px rgb(0 0 0 / 92%), 0 0px 2px rgb(0 0 0 / 94%);
  padding: 16px;
  border-radius: 4px;

  &__item {
    margin-bottom: 10px;
  }

  &__text {
    background: #00000012;
    padding: 15px 16px 14px;
    border-radius: 4px;
    min-height: 56px;
  }

  &__label {
    margin-bottom: 2px;
    font-weight: 600;
    sup {
      font-size: 12px;
    }
  }

  input {
    border: 1px solid grey;
    border-radius: 4px;
  }

  .dx-texteditor {
    &:before {
      display: none;
    }
    input {
      color: gray;
      min-height: 56px;
    }
    .dx-texteditor-label {
      span {
        font-size: 12px;
      }
    }
  }
}
.dx-texteditor.dx-editor-filled.dx-state-disabled .dx-texteditor-input {
  color: #000000;
}

iframe {
  display: none;
}

.dx-tabs-wrapper {
  margin-bottom: 20px;
}


</style>
