import defaultLayout from "@/layouts/side-nav-outer-toolbar";
import loadView from "../common/loadView";

export default [
  {
    path: "/city/:mode/:id?",
    name: "city",
    meta: {
      requiresAuth: true,
      requiresPermission: true,
      layout: defaultLayout,
      navPermission: "Город"
    },
    component: loadView("cities/city-detail-view"),
  },
  {
    path: "/cities",
    name: "cities",
    meta: {
      requiresAuth: true,
      requiresPermission: true,
      layout: defaultLayout,
      navPermission: "Город"
    },
    component: loadView("cities/cities-list-view"),
  },
];
