import defaultLayout from "@/layouts/side-nav-outer-toolbar";
import loadView from "../common/loadView";

export default [
  {
    path: "/brand/:mode/:id?",
    name: "brand",
    meta: {
      requiresAuth: true,
      requiresPermission: true,
      layout: defaultLayout,
      navPermission: "Марка авто"
    },
    component: loadView("brands-models/brand-detail-view"),
  },
  {
    path: "/brands",
    name: "brands",
    meta: {
      requiresAuth: true,
      requiresPermission: true,
      layout: defaultLayout,
      navPermission: "Марка авто"
    },
    component: loadView("brands-models/brands-list-view"),
  },
];
