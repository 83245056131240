import defaultLayout from "@/layouts/side-nav-outer-toolbar";
import loadView from "../common/loadView";

export default [
  {
    path: "/rent-car/:mode/:id?",
    name: "rent-car",
    meta: {
      requiresAuth: true,
      requiresPermission: true,
      layout: defaultLayout,
      navPermission: "Арендный авто"
    },
    component: loadView("rents/rent-car/rent-car-detail-view"),
  },
  {
    path: "/rent-cars",
    name: "rent-cars",
    meta: {
      requiresAuth: true,
      requiresPermission: true,
      layout: defaultLayout,
      navPermission: "Арендный авто"
    },
    component: loadView("rents/rent-car/rent-cars-list-view"),
  },
];
