import { createRouter, createWebHistory } from "vue-router";
import { useSecurityStore } from "@/stores/security";
import { usePermissionsStore } from "@/stores/permissions";


function loadModules() {
  const context = require.context("./modules", false, /([a-z_]+)\.js$/i);
  const keys = context.keys();
  let modules = [];
  keys.forEach(key => {
    modules.push(context(key).default)
  });
  return modules.flat();
}
const modules = loadModules();

const router = new createRouter({
  routes: [
    ...modules,
    {
      path: "/",
      redirect: "home",
    },
    {
      path: '/:applicationName/view/:id',
      redirect: to => {
        let applicationNameLower = to.params.applicationName.toLowerCase();
        let newPathSegment = '';
        switch (applicationNameLower) {
        case 'rentcarapplcation':
            newPathSegment = 'rent-application';
            break;
          case 'clientrentcarapplcation':
            newPathSegment = 'private-rent-app';
            break;
          case 'photoreport':
            newPathSegment = 'photo-report';
            break;
          case 'connectedbranding':
            newPathSegment = 'connected-branding';
            break;
          default:
            newPathSegment = '';
            break;
        }
        return newPathSegment ? { path: `/${newPathSegment}/view/${to.params.id}` } : { path: `/` }
      }
    }
  ],
  history: createWebHistory(),
});

router.beforeEach((to, from, next) => {

  const securityStore = useSecurityStore();
  const permissionsStore = usePermissionsStore();

  const permissions = permissionsStore.check(to.meta.navPermission, "navigate") || null;
  const checkPermissions = Array.isArray(securityStore.permissions) && securityStore.permissions.length 
 
  const loggedIn = securityStore.loggedIn;
  
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth)
  const userType = process.env.VUE_APP_USER_TYPE;

  if (requiresAuth && !loggedIn) {

    next({
      name: "login-form",
      query: { redirect: to.fullPath },
    });

  } else if (to.name === "login-form" && loggedIn) {

 
    const redirectName = userType === "Manager" ? "rent-cars" : "branding-dashboard"

    next({ name: redirectName });


  } else if (to.name !== "home" 
      && !permissions && to.name !== "login-form" 
      && to.name !== "reset-password" 
      && to.name !== "change-password") { 
    next({ name: "home" });
  } else if (to.name === "home" 
    && userType !== "Manager" 
    && checkPermissions
    && securityStore.permissions[0].targetType === "Дашборд(Авто)") {
    
    next({ name: "car-dashboard" })
    
  } else if (to.name === "home"
    && userType === "Manager"
    && checkPermissions
    && securityStore.permissions.some(item => item.targetType === "Арендный авто")) {
      next({ name: "rent-cars" })
    
    }
    else next();
});

export default router;
