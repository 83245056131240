import loadView from "../common/loadView";
import simpleLayout from "@/layouts/single-card";

export default [
  {
    path: "/login-form",
    name: "login-form",
    meta: {
      requiresAuth: false,
      layout: simpleLayout,
      title: "SignIn",
    },
    component: loadView("security/login-form"),
  },
  {
    path: "/reset-password",
    name: "reset-password",
    meta: {
      requiresAuth: false,
      layout: simpleLayout,
      title: "ResetPassword",
    },
    component: loadView("security/reset-password-form"),
  },
  {
    path: "/create-account",
    name: "create-account",
    meta: {
      requiresAuth: false,
      layout: simpleLayout,
      title: "CreateAccount",
    },
    component: loadView("security/create-account-form"),
  },
  {
    path: "/change-password/:token/:userId",
    name: "change-password",
    meta: {
      requiresAuth: false,
      layout: simpleLayout,
      title: "ChangePassword",
    },
    component: loadView("security/change-password-form"),
  },
];
