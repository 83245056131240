import defaultLayout from "@/layouts/side-nav-outer-toolbar";
import loadView from "../common/loadView";

export default [
  {
    path: "/home",
    name: "home",
    meta: {
      requiresAuth: true,
      requiresPermission: true,
      layout: defaultLayout,
      navPermission: ""
    },
    component: loadView("home-page"),
  }
];
