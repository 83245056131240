import defaultLayout from "@/layouts/side-nav-outer-toolbar";
import loadView from "../common/loadView";

export default [
  {
    path: "/rent-application/:mode/:id?",
    name: "rentdetail",
    meta: {
      requiresAuth: true,
      requiresPermission: true,
      layout: defaultLayout,
      navPermission: "Заявка на аренду"
    },
    component: loadView("rents/rent-application/rent-app-detail-view"),
  },
  {
    path: "/rents-applications/",
    name: "rents",
    meta: {
      requiresAuth: true,
      requiresPermission: true,
      layout: defaultLayout,
      navPermission: "Заявка на аренду"
    },
    component: loadView("rents/rent-application/rents-app-list-view"),
  },
];
