import defaultLayout from "@/layouts/side-nav-outer-toolbar";
import loadView from "../common/loadView";

export default [
  {
    path: "/client/:mode/:id?",
    name: "client",
    meta: {
      requiresAuth: true,
      requiresPermission: true,
      layout: defaultLayout,
      navPermission: "Клиенты"
    },
    component: loadView("clients/client-tabs-view"),
  },
  {
    path: "/clients",
    name: "clients",
    meta: {
      requiresAuth: true,
      requiresPermission: true,
      layout: defaultLayout,
      navPermission: "Клиенты"
    },
    component: loadView("clients/clients-list-view"),
  },
];
