import defaultLayout from "@/layouts/side-nav-outer-toolbar";
import loadView from "../common/loadView";

export default [
  {
    path: "/branding-dashboard",
    name: "branding-dashboard",
    meta: {
      requiresAuth: true,
      requiresPermission: true,
      layout: defaultLayout,
      navPermission: "Дашборд(Брендинг)"
    },
    component: loadView("dashboards/branding-dashboard-view"),
  },
  {
    path: "/car-dashboard",
    name: "car-dashboard",
    meta: {
      requiresAuth: true,
      requiresPermission: true,
      layout: defaultLayout,
      navPermission: "Дашборд(Авто)"
    },
    component: loadView("dashboards/car-dashboard-view"),
  },
];
