import defaultLayout from "@/layouts/side-nav-outer-toolbar";
import loadView from "../common/loadView";

export default [
  {
    path: "/logs/:oid?",
    name: "logs",
    meta: {
      requiresAuth: true,
      requiresPermission: true,
      layout: defaultLayout,
      navPermission: "Изменения"
    },
    component: loadView("logs/logs-list-view"),
  },
];
