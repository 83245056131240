import defaultLayout from "@/layouts/side-nav-outer-toolbar";
import loadView from "../common/loadView";

export default [
  {
    path: "/private-rent-app/:mode/:id?",
    name: "private-rent-app",
    meta: {
      requiresAuth: true,
      requiresPermission: true,
      layout: defaultLayout,
      navPermission: "Заявка на частные авто"
    },
    component: loadView("rents/private-rent-app/private-rent-app-detail-view"),
  },
  {
    path: "/private-rent-apps",
    name: "private-rent-apps",
    meta: {
      requiresAuth: true,
      requiresPermission: true,
      layout: defaultLayout,
      navPermission: "Заявка на частные авто"
    },
    component: loadView("rents/private-rent-app/private-rent-apps-list-view"),
  },
];
