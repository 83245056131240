import { defineStore } from "pinia";
import axios from "../axios/requestAxios";
import { v4 as generateGuid } from "uuid";
import notify from "devextreme/ui/notify";
import { usePermissionsStore } from "./permissions";
import { useSideMenuStore } from "./side-menu";
import jwt_decode from "jwt-decode";


export const useSecurityStore = defineStore("security", {
  persist: true, // позволяет записать стейт в локалсторедж
  state: () => {
    return {
      loading: false,
      user: null,
      permissions: null,
      token: null,
      refreshToken: null,
      refreshTokenTimeout: null,
      email: null,
      createUser: {},
      userName: null
    };
  },
  getters: { 
    loggedIn() {  
      return this.token !== null
    },
    oid() {
      return this.user?.oid
    },
    isLoading() {
      return this.loading
    },
    userName() {
      return this.user
    },
    userType() {
      return this.user?.usertype
    }  
  },
  actions: {
    extractDataFromJWT(token){
      var decoded = jwt_decode(token)
      this.email = decoded.email;
      this.userName = decoded.email;
    },
    setJwtData(jwtToken, permissions, token, refreshToken) {
      this.user = jwtToken;
      this.permissions = permissions;
      this.token = token;
      this.refreshToken = refreshToken;

      const permissionsStore = usePermissionsStore();
      permissionsStore.permissions = permissions;

      const sideMenuStore = useSideMenuStore();
      sideMenuStore.$reset();
    },
    destroyJwtData() {
      this.user = null;
      this.permissions = {};
      this.token = null;
      this.email = null;
      
      const permissionsStore = usePermissionsStore();
      permissionsStore.permissions = {};
      localStorage.clear();
      sessionStorage.clear();

      const sideMenuStore = useSideMenuStore();
      sideMenuStore.$reset();
    },
    async Login(credentials) {
      this.loading = true;
      return new Promise((resolve, reject) => {
        axios
          .post("api/Security/Login", { // отправляем запрос на сервер
            Email: credentials.email,
            Password: credentials.password,
            SessionID: generateGuid(),
          })
          .then(async (response) => { //  в ответ получаем токен пользователя и рефреш токен для локал стораджа
            const accessToken = response.data.token; 
            const permissions = response.data.permissions;
            var token = response.data.token;
            var refreshTokenVal = response.data.refreshToken;
            var jwtToken = jwt_decode(accessToken)

            this.setJwtData(jwtToken, permissions, token, refreshTokenVal);
            resolve(response);
          })
          .catch((error) => {
            notify(error.response?.data?.detail, "error", 2000); 
            reject(error.response);
          }).finally(() => {
            this.loading = false;
          });
      });
    },

    async clientLogin(credentials) {
      this.loading = true;
      return new Promise((resolve, reject) => {
        axios
          .post("/api/Security/LoginForMobileUser", credentials)
          .then(async (response) => { //  в ответ получаем токен пользователя и рефреш токен для локал стораджа
            const accessToken = response.data.token;
            const permissions = response.data.permissions;
            var token = response.data.token;
            var refreshTokenVal = response.data.refreshToken;
            var jwtToken = jwt_decode(accessToken)

            this.setJwtData(jwtToken, permissions, token, refreshTokenVal);
            resolve(response);
          })
          .catch((error) => {
            notify(error.response?.data?.detail, "error", 2000);
            reject(error.response);
          }).finally(() => {
            this.loading = false;
          });
      });
    },

    async Register(user) {
      this.loading = true;
      return new Promise((resolve, reject) => {
        axios
          .post("api/Security/Register", user)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            notify(error.response.data?.detail, "error", 2000); 
            reject(error.response);
          }).finally(() => {
            this.loading = false;
          });
      });
    },
    async FastRegister(user) {
      this.loading = true;
      return new Promise((resolve, reject) => {
        axios
          .post("api/MobileSecurity/FastRegister", user)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            notify(error.response.data?.detail, "error", 2000); 
            reject(error.response);
          }).finally(() => {
            this.loading = false;
          });
      });
    },
    async ResetPassword(email) {
      this.loading = true;
      return new Promise((resolve, reject) => {
        axios
          .post("api/Security/ResetPassword", {
            Oid: generateGuid(),
            Email: email,
          })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            notify(error.response.data.message, "error", 2000);
            reject(error.response);
          }).finally(() => {
            this.loading = false;
          });
      });
    },
    async ValidateResetToken(request) {
      this.loading = true;
      return new Promise((resolve, reject) => {
        axios
          .post("api/Security/ValidateToken", request)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            notify(error.response.data.message, "error", 2000);
            console.dir(error);
            reject(error.response);
          })
          .finally(() => {
            this.loading = false;
          });
      });
    },
    async ChangePassword(request) {
      this.loading = true;
      return new Promise((resolve, reject) => {
        request.SessionID = generateGuid();
        axios
          .post("api/Security/ChangePassword", request)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            notify(error.response.data.message, "error", 2000);
            reject(error.response);
          }).finally(() => {
            this.loading = false;
          });
      });
    },
    async issueNewToken(refreshToken) {
      this.loading = true;
      return new Promise((resolve, reject) => {
        axios
          .post("api/Security/IssueNewToken", {
            refreshToken: refreshToken,
          })
          .then(async (response) => {
            const accessToken = response.data.token;
            const permissions = response.data.permissions;
            var token = response.data.token;
            var refreshTokenVal = response.data.refreshToken;

            var jwtToken = jwt_decode(accessToken)
            
            this.setJwtData(jwtToken, permissions, token, refreshTokenVal);
            resolve(response);
          })
          .catch((error) => {
            notify(error.response.data.message, "error", 2000);
            reject(error.response);
          }).finally(() => {
            this.loading = false;
          });
      });
    },
    async issueNewClientToken(refreshToken) {
      this.loading = true;
      return new Promise((resolve, reject) => {
        axios
          .post("api/Security/IssueNewTokenForMobileUser", {
            refreshToken: refreshToken,
          })
          .then(async (response) => {
            const accessToken = response.data.token;
            const permissions = response.data.permissions;
            var token = response.data.token;
            var refreshTokenVal = response.data.refreshToken;

            var jwtToken = jwt_decode(accessToken)

            this.setJwtData(jwtToken, permissions, token, refreshTokenVal);
            resolve(response);
          })
          .catch((error) => {
            notify(error.response.data.message, "error", 2000);
            reject(error.response);
          }).finally(() => {
            this.loading = false;
          });
      });
    },
  },
});
