import { defineStore } from "pinia";
import { useSecurityStore } from "./security";

export const usePermissionsStore = defineStore("permissions", {
  persist: true, // Опция persist включена, что позволяет сохранять состояние хранилища между обновлениями страницы.
  state: () => {
    return {
      permissions: null,
    };
  },
  actions: {
    check(key, permission) {
      const securityStore = useSecurityStore();
      // проверка , что бы find не ломался, если permissions {} 
      const permissions = Array.isArray(securityStore.permissions)  
        ? securityStore.permissions
        : ["Меню"];
        
      // Получаем разрешения из хранилища "security" или, если они отсутствуют, устанавливаем значение по умолчанию ["Меню"].  
      var actualPermissions = permissions.find((x) => x.targetType === key);

      if (actualPermissions == null) {
        return false;
      }

      // В зависимости от типа разрешения, проверяем соответствующие поля объекта разрешения и возвращаем true или false.
      switch (permission) {
        case "create":
          return actualPermissions.allowCreate;
        case "delete":
          return actualPermissions.allowDelete;
        case "edit":
          return actualPermissions.allowWrite;
        case "read":
          return actualPermissions.allowRead;
        case "navigate":
          return actualPermissions.allowNavigate;
      }
    },
  },
});
