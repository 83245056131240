import defaultLayout from "@/layouts/side-nav-outer-toolbar";
import loadView from "../common/loadView";

export default [
  {
    path: "/connected-branding/:mode/:id?",
    name: "connected-branding",
    meta: {
      requiresAuth: true,
      requiresPermission: true,
      layout: defaultLayout,
      navPermission: "Подключенные брендинги"
    },
    component: loadView("connected-branding/connected-branding-detail-view"),
  },
  {
    path: "/connected-brandings",
    name: "connected-brandings",
    meta: {
      requiresAuth: true,
      requiresPermission: true,
      layout: defaultLayout,
      navPermission: "Подключенные брендинги"
    },
    component: loadView("connected-branding/connected-branding-list-view"),
  },
];
