import defaultLayout from "@/layouts/side-nav-outer-toolbar";
import loadView from "../common/loadView";


export default [
    {
      path: "/all-tabs",
      name: "all-tabs",
      meta: {
        requiresAuth: true,
        requiresPermission: true,
        layout: defaultLayout,
        navPermission: "Новости"
      },
      component: loadView("info-news/all-tabs"),
    },
    {
      path: "/blog/",
      name: "blog",
      meta: {
        requiresAuth: true,
        requiresPermission: false,
        layout: defaultLayout,
        navPermission: "Новости"
      },
      component: loadView("info-news/blog-ditail")
    },
    {
      path: "/blog/post/:mode/:id?",
      name: "blogpost",
      meta: {
        requiresAuth: true,
        requiresPermission: true,
        layout: defaultLayout,
        navPermission: "Новости"
      },
      component: loadView("info-news/blog-detail")
    },
    {
      path: "/notification/:mode/:id?",
      name: "notification",
      meta: {
        requiresAuth: true,
        requiresPermission: true,
        layout: defaultLayout,
        navPermission: "Рассылки"
      },
      component: loadView("info-news/notification-detail")
    },
    {
      path: "/carousel/:mode/:id?",
      name: "carousel",
      meta: {
        requiresAuth: true,
        requiresPermission: true,
        layout: defaultLayout,
        navPermission: "Карусель"
      },
      component: loadView("info-news/carousel-detail")
    }
  ];