import defaultLayout from "@/layouts/side-nav-outer-toolbar";
import loadView from "../common/loadView";

export default [
  {
    path: "/photo-report/:mode/:id?",
    name: "photo-report",
    meta: {
      requiresAuth: true,
      requiresPermission: true,
      layout: defaultLayout,
      navPermission: "Фотоотчет"
    },
    component: loadView("photo-report/photo-report-detail-view"),
  },
  {
    path: "/photo-reports",
    name: "photo-reports",
    meta: {
      requiresAuth: true,
      requiresPermission: true,
      layout: defaultLayout,
      navPermission: "Фотоотчет"
    },
    component: loadView("photo-report/photo-report-list-view"),
  },
];
