import defaultLayout from "@/layouts/side-nav-outer-toolbar";
import loadView from "../common/loadView";

export default [
  {
    path: "/transactions",
    name: "transactions",
    meta: {
      requiresAuth: true,
      requiresPermission: true,
      layout: defaultLayout,
      navPermission: "Транзакции"
    },
    component: loadView("transaction/transaction-list-view"),
  },
];
